import {
  descargarCotizacion,
  compartirCotizacion,
} from "@/services/cotizacion.js";

export const GenShare = {
  data: () => ({
    // == Elementos de carga ==
    loadShare: false, // Carga de compartir simulacion
    loadQuot: false, // Carga descargar simulacion
  }),
  methods: {
    // === Enviar cotizacion por correo ===
    sendQuot(i, idQuote) {
      this.loadShare = true;
      let message, type;
      compartirCotizacion(i, idQuote)
        .then((r) => {
          message = r;
          type = "success";
        })
        .catch((e) => {
          message = e;
          type = "error";
        })
        .finally(() => {
          this.loadShare = false;
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: type,
          });
        });
    },

    // === Generar archivo de cotizacion ===
    GenerateQuot(i, idQuote) {
      this.loadQuot = true;
      let message, type;
      descargarCotizacion(i, idQuote)
        .then((r) => {
          message = r;
          type = "success";
        })
        .catch((e) => {
          message = e.toString();
          type = "error";
        })
        .finally(() => {
          this.loadQuot = false;
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: type,
          });
        });
    },
  },
};

export default GenShare;
